/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Документы`,
    TERMS: `Пользовательское Соглашение`,
    PRIVACY: `Политика конфиденциальности`,
    COOKIE: `Политика использования Сookie`,
    AML: `Политика AML`,
    CONDUCT_CODE: `Кодексы поведения`,
    CUSTOMER_SERVICE: `Поддержка пользователей`,
    HELP_CENTER: `Центр поддержки`,
    FEEDBACK: `Обратная передача мнения`,
    CONTACTS: `Свяжитесь с нами`,
    OUR_EMAIL: `Наши электронные письма`,
    CHARGES: `Тарифы`,
    APP_DOWNLOAD: `Скачать приложение`,
    MODAL_TITLE: `Напоминание`,
    MODAL_CONTENT: `Будьте на связи`,
    MODAL_BUTTON: `Окей`
};
