/**
 * index.js for translation
 **/
import setLanguage from 'utils/language';
import ja from './ja';
import zh from './zh-tw';
import en from './en';
import ru from './ru';
import es from './es';

const languages = [
    { code: 'zh', language: zh, name: '繁体中文' },
    { code: 'ja', language: ja, name: 'Japanese' },
    { code: 'en', language: en, name: 'English' },
    { code: 'ru', language: ru, name: 'Russia' },
    { code: 'es', language: es, name: 'Spanish' },
];
let language = null;

export const getLanguage = () => language;

export default (code = 'default') => {
    language = setLanguage(code, languages);
    return language.language;
};