/**
 * 公共接口
 */
export default SERVER => ({

  /**
   * 全局配置信息
   * method GET
   */
  COMMON_CONFIG: `${SERVER}/config`,

  /**
   * 帮助中心文档
   * method GET
   */
  COMMON_SUPPORT: `${SERVER}/common/docs`,

  /**
   * 获取图片验证码
   * method GET
   */
  COMMON_CAPTCHA: `${SERVER}/auth/captcha`,

  /**
   * BTC 汇率
   * method GET
   */
  COMMON_BTC_CONFIG: `${SERVER}/config/btc`,

  /**
   * btc & eth 价格
   * method GET
   */
  COMMON_SYMBOL_CONFIG: `${SERVER}/config/symbols`,
});