/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Terms of Service`,
    TERMS: `User Agreement`,
    PRIVACY: `Privacy Policy`,
    COOKIE: `Cookie Policy`,
    AML: `AML Policy`,
    CONDUCT_CODE: `Code of Conduct`,
    CUSTOMER_SERVICE: `Customer Service`,
    HELP_CENTER: `Help Center`,
    FEEDBACK: `Feedback`,
    CONTACTS: `Contact Us`,
    OUR_EMAIL: `Our Email`,
    CHARGES: `Charges`,
    APP_DOWNLOAD: `APP download`,
    MODAL_TITLE: `Prompt`,
    MODAL_CONTENT: `Comming Soon!`,
    MODAL_BUTTON: `Confirm`
};
