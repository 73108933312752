/**
 * 语言包
 * @param code 浏览器语言code (default为浏览器语言)
 * @param languages 语言包配置形如: [{code: 'en', languages: en}, {code: 'zh-cn', languages: zh}]
 * @return 对应语言包的language
 */
export default (code, languages) => {
  if (code === 'default') {
    code = languageCode();
  }
  let language = languages.filter(v => v.code === code);
  if (language.length === 0) {
    language = languages[0];
  } else {
    language = language[0];
  }
  return language;
};

/**
 * 浏览器语言
 */
export function languageCode() {
  const code = (navigator.browserLanguage || navigator.language).toLowerCase().split('-')[0];
  // const fullCode = (navigator.browserLanguage || navigator.language).toLowerCase();
  // if (fullCode === 'zh-cn') { // 国内默认英文
  //   return 'en';
  // }

  // if (code === 'zh') { // 去掉繁体中文
  //   return code;
  // }
  if (code === 'ja') {
    return code;
  }
  if (code === 'ru') {
    return code;
  }
  if (code === 'es') {
    return code;
  }

  return 'en';
}

/**
 * 接口 Header language
 */
export function apiLanguageCode() {
  const code = languageCode();
  if (code === 'zh') {
    return 'zh-TW';
  }
  return code;
}
