/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Página principal`,
    MENU_ASSET: `Activos`,
    MENU_PLAN: `Servicios`,
    MENU_ME: `Mi perfil`,
    LOGOUT: `Salir`,
    LOGIN: `Iniciar sesión`
};
