/**
 * 首页接口
 */
export default SERVER => ({
  
  /**
   * 首页数据
   * method GET
   */
  HOME_DATA: `${SERVER}/home`,

  /**
   * 首页资讯
   * method GET
   */
  HOME_ARTICLES: `${SERVER}/articles`,

  /**
   * 首页 区块链入门
   * method GET
   */
  HOME_SCHOOLS: `${SERVER}/schools`,

  /**
   * 首页行情
   * method GET
   */
  HOME_MARKET: `${SERVER}/tickers`,

  /**
   * 资讯详情
   * method GET
   */
  HOME_ARTICLE: `${SERVER}/doc`,
  
});
