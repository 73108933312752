/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `首页`,
    MENU_ASSET: `資產`,
    MENU_PLAN: `服務`,
    MENU_ME: `我的`,
    LOGOUT: `退出`,
    LOGIN: `登錄`
};
