/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Declaración de términos`,
    TERMS: `Acuerdo de usuario`,
    PRIVACY: `Política de privacidad`,
    COOKIE: `Política de cookies`,
    AML: `Política contra el lavado de dinero`,
    CONDUCT_CODE: `Código de conducta`,
    CUSTOMER_SERVICE: `Servicio al cliente`,
    HELP_CENTER: `Centro de ayuda`,
    FEEDBACK: `Retroalimentación`,
    CONTACTS: `Contáctenos`,
    OUR_EMAIL: `Nuestros correos electrónicos`,
    CHARGES: `Tarifas`,
    APP_DOWNLOAD: `Descargar la APP`,
    MODAL_TITLE: `Aviso`,
    MODAL_CONTENT: `Esperamos con interés`,
    MODAL_BUTTON: `Confirmar`
};
