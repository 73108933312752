import React from 'react';
import PropTypes from 'prop-types';

// utils
import  { isSignIn } from '../../utils/user';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentMobileHeader';

class MobileHeader extends React.PureComponent {
  static propTypes = {
    // action
    onNavMoreBtnClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.L = L();
  }

  // private
  onAssetClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/asset';
  }

  onServiceClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/qt';
  }

  // render
  render() {
    // 当前菜单
    const menu = window.location.pathname.split('/');

    return (
      <div className={`${className}`}>
        <div className={`${className}-box`}>
          <div className={`${className}-left`} onClick={() => window.location.href = '/'}>
            <img className={`${className}-logo`} src={ICONS.LOGO_TEXT} />
            <div className={`${className}-line`} />
            <div className={`${className}-name`}>{this.L.SLOGAN}</div>
          </div>
          <div className={`${className}-right`} onClick={() => this.props.onNavMoreBtnClick()}>
            <img className={`${className}-icon`} src={ICONS.MOBILE_MORE} />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileHeader;
