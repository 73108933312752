export default {
  ARROW_RIGHT: require('./arrow-right.png'),
  AVATAR_DEFAULT: require('./avatar-default.png'),
  EXCHANGE: require('./exchange.png'),
  EXCHANGE_COIN: require('./exchange-coin.png'),
  EXCHANGE_DOLLAR: require('./exchange-dollar.png'),
  LOGO: require('./logo.png'),
  LOGO_TEXT: require('./logo-text.png'),
  
  ME_KYC: require('./me-kyc.png'),
  ME_TEAM: require('./me-team.png'),
  ME_PWD: require('./me-pwd.png'),
  ME_TRADE_PWD: require('./me-trade-pwd.png'),

  ME_STATUS_YES: require('./me-status-yes.png'),
  ME_STATUS_NO: require('./me-status-no.png'),

  MOBILE_MORE: require('./mobile-more.png'),

  QT_EXCHANGE: require('./qt-exchange.png'),
  QT_RECORD: require('./qt-record.png'),

  NAV_BACK_WHITE: require('./nav-back-white.png'),
  NAV_BACK: require('./nav-back.png'),
  NAV_BELL_DOT: require('./nav-bell-dot.png'),
  NAV_BELL: require('./nav-bell.png'),

  QUESTION_MARK: require('./question-mark.png'),

  HEADER_ASSET: require('./header-asset.png'),
  HEADER_HOME: require('./header-home.png'),
  HEADER_LOGIN: require('./header-login.png'),
  HEADER_LOGOUT: require('./header-logout.png'),
  HEADER_ME: require('./header-me.png'),
  HEADER_PLAN: require('./header-plan.png'),

  TAB_ASSET_SELECTED: require('./tab-asset-selected.png'),
  TAB_ASSET: require('./tab-asset.png'),
  TAB_HOME_SELECTED: require('./tab-home-selected.png'),
  TAB_HOME: require('./tab-home.png'),
  TAB_ME_SELECTED: require('./tab-me-selected.png'),
  TAB_ME: require('./tab-me.png'),
  TAB_SERVICE_SELECTED: require('./tab-service-selected.png'),
  TAB_SERVICE: require('./tab-service.png'),

  TEAM_HALF_CIRCLE_LEFT: require('./team_half_circle_left.png'),
  TEAM_HALF_CIRCLE_RIGHT: require('./team_half_circle_right.png'),

  APPLE: require('./apple.svg'),
  ANDROID: require('./android.svg'),

  FACEBOOK: require('./platform/facebook.png'),
  TWITTER: require('./platform/twitter.png'),
  TELEGRAM: require('./platform/telegram.png'),
  INSTAGRAM: require('./platform/instagram.png'),
  WHATSAPP: require('./platform/whatsapp.png'),
  TIKTOK: require('./platform/tiktok.png'),

  RIGHT: require('./right.png'),

  MORE_BLUE: require('./more-blue.png'),
  
  GUIDE_ARROW: require('./guide-arrow.png'),
  
  ICON_BTC: require('./coins/icon-btc.svg'),
  ICON_ETH: require('./coins/icon-eth.svg'),
  ICON_LTC: require('./coins/icon-ltc.svg'),
  ICON_ADA: require('./coins/icon-ada.svg'),
  ICON_BCH: require('./coins/icon-bch.svg'),
  ICON_SHIB: require('./coins/icon-shib.svg'),
  ICON_EOS: require('./coins/icon-eos.svg'),
  ICON_LINK: require('./coins/icon-link.svg'),
  ICON_UNI: require('./coins/icon-uni.svg'),
  ICON_XRP: require('./coins/icon-xrp.svg'),
  ICON_SOLANA: require('./coins/icon-solana.svg'),
};
