/**
 * 登录注册接口
 */
export default SERVER => ({
  /**
   * 发送验证码
   * method POST
   */
  AUTH_SEND_CODE: `${SERVER}/auth/send_code`,

  /**
   * 注册
   * method POST
   */
  AUTH_SIGN_UP_EMAIL: `${SERVER}/auth/register_by_email`,
  AUTH_SIGN_UP_MOBILE: `${SERVER}/auth/register_by_mobile`,

  /**
   * 登录
   * method POST
   */
  AUTH_SIGN_IN: `${SERVER}/auth/login`,
  
  /**
   * 修改密码
   * method POST
   */
  AUTH_CHANGE_PWD: `${SERVER}/user/password`,

  /**
   * 忘记密码
   * method POST
   */
  AUTH_FORGET_PWD: `${SERVER}/auth/forget`,

});
