/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_1: `首页`,
    MENU_2: `資產`,
    MENU_3: `服務`,
    MENU_4: `我的`,
    SIGNIN: `登錄`,
    SIGNUP: `註冊`,
    SLOGAN: `區塊鏈小蜜蜂`
};
