/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `条例説明`,
    TERMS: `ユーザー条例`,
    PRIVACY: `プライバシポリシー`,
    COOKIE: `Cookieポリシー`,
    AML: `マネーロンダリング防止ポリシー`,
    CONDUCT_CODE: `行動規範`,
    CUSTOMER_SERVICE: `サポートサービス`,
    HELP_CENTER: `ヘルプセンター`,
    FEEDBACK: `フィードバック`,
    CONTACTS: `コンタクト`,
    OUR_EMAIL: `私たちのメール” (わたしたちのメール)`,
    CHARGES: `利用料金`,
    APP_DOWNLOAD: `APPダウンロード`,
    MODAL_TITLE: `ヒント`,
    MODAL_CONTENT: `お楽しみに！`,
    MODAL_BUTTON: `決定`
};
