/**
 * 资产接口
 */
export default SERVER => ({
  /**
   * 我的资产
   * method GET
   */
  ASSET_DATA: `${SERVER}/asset`,

  /**
   * 法币记录
   * method GET
   */
  USDT_LOGS: `${SERVER}/asset/flows`,

  /**
   * 提现
   * method POST
   */
  ASSET_WITHDRAW: `${SERVER}/asset/despoit`,

  /**
   * 资产配置
   * method GET
   */
  ASSET_CONFIG: `${SERVER}/config/asset`,

  // 墨西哥通道
  /**
   * 入金 - 墨西哥
   * method POST
   */
  BANK_RECHARGE_MXN: `${SERVER}/bank/recharge/mxn`,
  /**
   * 出金 - 墨西哥
   * method POST
   */
  BANK_CASHOUT_MXN: `${SERVER}/bank/cashout/mxn`,

  
});
