/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Home`,
    MENU_ASSET: `Assets`,
    MENU_PLAN: `Service`,
    MENU_ME: `My`,
    LOGOUT: `Exit`,
    LOGIN: `Login`
};
