// -----------------------------
// 常量配置
// -----------------------------
import keyMirror from 'keymirror';

// eslint-disable-next-line
export const LOCAL_STORAGE = keyMirror({
  CONFIG: null,
  ACCESS_TOKEN: null,
  USER_INFO: null,

  GUIDE_ASSET_COUNT: null,
  GUIDE_QT_COUNT: null,
});

/**
 * USDT记录类型
 */
export const USDT_LOG_TYPE = {
  // 充币
  RECHARGE: 3,
  // 提币
  WITHDRAW: 4,
  // 法币兑换积分
  USDT_2_SCORE: 5,
  // 积分兑换法币
  SCORE_2_USDT: 6,
  // 代理返佣
  REBATE: 7,
};

/**
  * 充值/提现记录状态
  */
export const USDT_LOG_STATUS = {
 /// 审核中
 PROCESSING: 0,
 /// 成功
 SUCCESS: 1,
 /// 失败
 FAIL: 2,
}

/**
 * 法币金额小数位数
 */
export const MONEY_DECIMAL = 2;

/**
 * USDT金额小数位数
 */
export const USDT_DECIMAL = 2;

/**
 * 法币单位
 */
export const MONEY_UNIT = 'USDT';

/**
 * USDT赠送金额
 */
export const USDT_TEST_COUNT = 10;

/**
 * 引导显示次数
 */
export const GUIDE_COUNT = 1;

/**
  * 充提币 支持币种
  */
export const RECHARGE_SUPPORT_COINS = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDTERC20: 'USDT(ERC20)',
  // USDTTRC20: 'USDT(TRC20)',
}