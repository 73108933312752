/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `титульная страница`,
    MENU_ASSET: `Активы`,
    MENU_PLAN: `Сервис`,
    MENU_ME: `Мои`,
    LOGOUT: `Выход`,
    LOGIN: `Войти`
};
