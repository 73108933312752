/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_1: `Inicio`,
    MENU_2: `Activos`,
    MENU_3: `Servicios`,
    MENU_4: `Mi perfil`,
    SIGNIN: `Iniciar sesión`,
    SIGNUP: `Registrarse`,
    SLOGAN: `Abeja de Blockchain`
};
