import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import {injectReducer} from '../store/reducers';
import rootStore from '../store';


// --------------  登录 注册 邀请注册  --------------
// 邀请注册
const Invite = lazy(() => import(/* webpackChunkName: "Invite" */ './Auth/Invite'));
// 登录
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ './Auth/SignIn'));
// 注册
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ './Auth/SignUp'));
// 忘记密码
const ForgetPwd = lazy(() => import(/* webpackChunkName: "ForgetPwd" */ './Auth/ForgetPwd'));

// --------------  首页  --------------
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Home/Index'));
const HomeArticle = lazy(() => import(/* webpackChunkName: "HomeArticle" */ './Home/Article'));
const HomeHelp = lazy(() => import(/* webpackChunkName: "HomeHelp" */ './Home/Help'));

// --------------  资产  --------------
// 资产首页
const Asset = lazy(() => import(/* webpackChunkName: "Asset" */ './Asset/Index'));
// Btc入金引导
const GuideBtc = lazy(() => import(/* webpackChunkName: "GuideBtc" */ './Asset/GuideBtc'));
// 快速入金
const CashIn = lazy(() => import(/* webpackChunkName: "CashIn" */ './Asset/Cash/In'));
// 快速出金
const CashOut = lazy(() => import(/* webpackChunkName: "CashOut" */ './Asset/Cash/Out'));

// --------------  量化交易  --------------
const Qt = lazy(() => import(/* webpackChunkName: "Qt" */ './Qt/Index'));
const QtRecord = lazy(() => import(/* webpackChunkName: "QtRecord" */ './Qt/Record'));

// --------------  我的  --------------
// 我的
const Me = lazy(() => import(/* webpackChunkName: "Me" */ './Me/Index'));
// 我的团队
const MyTeam = lazy(() => import(/* webpackChunkName: "MyTeam" */ './Me/Team'));
// 实名认证
const Kyc = lazy(() => import(/* webpackChunkName: "Kyc" */ './Me/Kyc'));
// 邀请
const Share = lazy(() => import(/* webpackChunkName: "Share" */ './Me/Share'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  // 登录 注册 邀请注册
  Invite,
  SignIn,
  SignUp,
  ForgetPwd,

  // 首页
  Home,
  HomeArticle,
  HomeHelp,

  // 资产
  Asset,
  GuideBtc,
  CashIn,
  CashOut,

  // 量化交易
  Qt,
  QtRecord,

  // 我的
  Me,
  MyTeam,
  Kyc,
  Share,

  PageNotFound,
};

// 查找reducer，component_name 到 path 的映射
const COMPONENT_PATH_MAPPING = {
  // 登录 注册 邀请注册
  'Invite': 'Auth/Invite',
  'SignIn': 'Auth/SignIn',
  'SignUp': 'Auth/SignUp',
  'ForgetPwd': 'Auth/ForgetPwd',

  // 主页
  'Home': 'Home/Index',
  'HomeArticle': 'Home/Article',
  'HomeHelp': 'Home/Help',

  // 服务
  'Qt': 'Qt/Index',
  'QtRecord': 'Qt/Record',

  // 资产
  'Asset': 'Asset/Index',
  'GuideBtc': 'Asset/GuideBtc',
  'CashIn': 'Asset/Cash/In',
  'CashOut': 'Asset/Cash/Out',
  
  // --------------  我的  --------------
  'Me': 'Me/Index',
  'MyTeam': 'Me/Team',
  'Kyc': 'Me/Kyc',
  'Share': 'Me/Share',

  'PageNotFound': 'PageNotFound',
}

const AsyncComponent = props => {
  const {componentName} = props;

  import(`./${COMPONENT_PATH_MAPPING[componentName]}/reducer`).then(({default: reducer}) => {
    injectReducer(rootStore, {key: componentName, reducer});
  });

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
