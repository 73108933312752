/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_1: `Home`,
    MENU_2: `Assets`,
    MENU_3: `Service`,
    MENU_4: `My`,
    SIGNIN: `Login`,
    SIGNUP: `Register`,
    SLOGAN: `Blockchain Bee`
};
