import React from 'react';

import './style.scss';

const className = 'componentMobileFooter';

class MobileFooter extends React.PureComponent {
  constructor(props) {
    super(props);

  }

  // private

  // render
  render() {
    return (
      <div className={`${className}`}>
        <div className={`${className}-copy-right`}>
          © WOX. All rights reserved.
        </div>
      </div>
    );
  }
}

export default MobileFooter;
