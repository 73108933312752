/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_1: `表紙`,
    MENU_2: `資産`,
    MENU_3: `サービス`,
    MENU_4: `マイ`,
    SIGNIN: `ログイン`,
    SIGNUP: `登録`,
    SLOGAN: `Blockchain Bee`
};
