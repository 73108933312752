export default {
  IMAGE_UPLOAD: require('./image-upload.png'),
  KYC_FAIL: require('./kyc-fail.png'),
  KYC_REVIEWING: require('./kyc-reviewing.png'),
  KYC_SUCCESS: require('./kyc-success.png'),
  LOGO: require('./logo.png'),
  NO_DATA_1: require('./no-data-1.png'),
  NO_DATA_2: require('./no-data-2.png'),
  QT_PROFIT: require('./qt-profit.png'),
  QT_TOP: require('./qt-top.png'),

  HOME_TOP: require('./home-top.png'),

  HOME_ICON_1: require('./home-icon-1.png'),
  HOME_ICON_2: require('./home-icon-2.png'),
  HOME_ICON_3: require('./home-icon-3.png'),

  HOME_ADVANTAGE_1: require('./home-advantage-1.png'),
  HOME_ADVANTAGE_2: require('./home-advantage-2.png'),
  HOME_ADVANTAGE_3: require('./home-advantage-3.png'),
  HOME_ADVANTAGE_4: require('./home-advantage-4.png'),
  
};
