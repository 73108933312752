import { ACTION_TYPES } from './action';

export const initialState = {
  coinPriceConfig: {
    btc: 0,
    eth: 0,
    mxn: 0.06, // 墨西哥汇率
  },

  
  exsit: false,
  info: {},

  assetConfig: {},
  
  isShowFooter: true,
  isShowMenu: false,
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.COMMON_SYMBOL_CONFIG]: (state, action) => {
    const newState = { ...state };
    newState.coinPriceConfig = action.data;
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.COMMON_SYMBOL_CONFIG}`]: state => {
    const newState = { ...state };
    return newState;
  },
  [`FAIL_${ACTION_TYPES.COMMON_SYMBOL_CONFIG}`]: state => {
    const newState = { ...state };
    return newState;
  },

  [ACTION_TYPES.GET_USER_KYC]: (state, action) => {
    const newState = { ...state };
    newState.requesting = false;
    newState.exsit = action.data.exsit;
    newState.info = action.data.info || {};
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.GET_USER_KYC}`]: state => {
    const newState = { ...state };
    newState.requesting = true;
    return newState;
  },
  [`FAIL_${ACTION_TYPES.GET_USER_KYC}`]: state => {
    const newState = { ...state };
    newState.requesting = false;
    return newState;
  },

  [ACTION_TYPES.ASSET_CONFIG]: (state, action) => {
    const newState = { ...state };
    newState.requesting = false;
    newState.assetConfig = action.data || {};
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.ASSET_CONFIG}`]: state => {
    const newState = { ...state };
    newState.requesting = true;
    return newState;
  },
  [`FAIL_${ACTION_TYPES.ASSET_CONFIG}`]: state => {
    const newState = { ...state };
    newState.requesting = false;
    return newState;
  },

  // 设置是否显示footer
  [ACTION_TYPES.COMMON_SET_SHOW_FOOTER]: (state, action) => {
    const newState = { ...state };
    newState.isShowFooter = action.data.isShowFooter;
    return newState;
  },
  // 设置显示menu
  [ACTION_TYPES.COMMON_SHOW_MENU]: (state, action) => {
    const newState = { ...state };
    newState.isShowMenu = action.data.isShow;
    return newState;
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
