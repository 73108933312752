/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `條款聲明`,
    TERMS: `用戶協議`,
    PRIVACY: `隱私政策`,
    COOKIE: `Cookie政策`,
    AML: `反洗錢政策`,
    CONDUCT_CODE: `行為準則`,
    CUSTOMER_SERVICE: `客服服務`,
    HELP_CENTER: `幫助中心`,
    FEEDBACK: `意見回饋`,
    CONTACTS: `聯繫我們`,
    OUR_EMAIL: `我們的電子郵件`,
    CHARGES: `收費標準`,
    APP_DOWNLOAD: `APP下載`,
    MODAL_TITLE: `提示`,
    MODAL_CONTENT: `敬請期待`,
    MODAL_BUTTON: `確定`
};
