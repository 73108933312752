/**
 * 服务接口
 */
export default SERVER => ({
  
  /**
   * 获取服务
   * method GET
   */
  QT_PLANS: `${SERVER}/plans`,

  /**
   * 购买服务
   * method POST
   */
  QT_BUY_PLAN: `${SERVER}/plan/buy`,
  
  /**
   * 资金划转-转入
   * method POST
   */
  QT_PLAN_RECHARGE: `${SERVER}/plan/recharge`,
  
  /**
   * 资金划转-转出
   * method POST
   */
  QT_PLAN_WITHDRAW: `${SERVER}/plan/despoit`,
  
  /**
   * 套利账户交易记录
   * method GET
   */
  QT_PLAN_RECORD: id => `${SERVER}/plan/${id}/flows`,

});
