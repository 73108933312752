import React from 'react';
import { Modal } from 'antd';

// utils
import {
  getPrivacyUrl,
  getTermsUrl,
  getCookieUrl,
  getAmlUrl,
  getConductUrl,
} from '../../utils/url';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentWebFooter';

const LINK_FB = 'https://www.facebook.com/profile.php?id=100056241082853';
const LINK_TWITTER = 'https://twitter.com/woxbtc';
const LINK_TELEGRAM = 'https://t.me/btcvipclub';
const LINK_INSTAGRAM = 'https://www.instagram.com/woxbtc';
const LINK_WHATSAPP = 'https://whatsapp.com/channel/0029VaepHuU0rGiNtCyd6B1w';
const LINK_TIKTOK = 'https://www.tiktok.com/@woxbtc';


class WebFooter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.L = L();
  }

  // private
  // 下载谷歌
  onDownloadGoogleClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.woxbtc.coin';

    // Modal.info({
    //   title: this.L.MODAL_TITLE,
    //   content: (
    //     <div>
    //       <p>{this.L.MODAL_CONTENT}</p>
    //     </div>
    //   ),
    //   okText: this.L.MODAL_BUTTON,
    //   onOk() {
    //   },
    // });
  }

  // 下载苹果
  onDownloadAppleClick = () => {
    // window.location.href = "itms-apps://itunes.apple.com/app/id1530171035";
    
    Modal.info({
      title: this.L.MODAL_TITLE,
      content: (
        <div>
          <p>{this.L.MODAL_CONTENT}</p>
        </div>
      ),
      okText: this.L.MODAL_BUTTON,
      onOk() {
      },
    });
  }

  // 帮助中心
  onHelpClick = () => {

  }

  // 意见反馈
  onFeedbackClick = () => {

  }

  // 联系我们
  onContactUsClick = () => {

  }

  // 收费标准
  onChargesClick = () => {

  }

  // render
  render() {
    const email = 'service@woxbtc.vip';

    return (
      <div className={`${className}`}>
        <div className={`${className}-inner`}>
          <div className={`${className}-box`}>
            <div className={`${className}-left-box`}>
              <div className={`${className}-copy-right-box`}>
                <img className={`${className}-logo`} src={ICONS.LOGO_TEXT} />
                <div className={`${className}-copy-right`}>
                  © WOX. All rights reserved.
                </div>
              </div>
              <div className={`${className}-email-box`}>
                <div className={`${className}-email-title`}>
                  {this.L.OUR_EMAIL}
                </div>
                <div
                  className={`${className}-email-value`}
                  onClick={() => window.open(`mailto:${email}`)}
                >
                  {email}
                </div>
              </div>
              <div className={`${className}-contact-us-box`}>
                {/* <img
                  className={`${className}-contact-icon`}
                  src={ICONS.FACEBOOK}
                  onClick={() => window.open(LINK_FB)}
                /> */}
                <img
                  className={`${className}-contact-icon`}
                  src={ICONS.TWITTER}
                  onClick={() => window.open(LINK_TWITTER)}
                />
                <img
                  className={`${className}-contact-icon`}
                  src={ICONS.TELEGRAM}
                  onClick={() => window.open(LINK_TELEGRAM)}
                />
                <img
                  className={`${className}-contact-icon`}
                  src={ICONS.INSTAGRAM}
                  onClick={() => window.open(LINK_INSTAGRAM)}
                />
                <img
                  className={`${className}-contact-icon`}
                  src={ICONS.WHATSAPP}
                  onClick={() => window.open(LINK_WHATSAPP)}
                />
                <img
                  className={`${className}-contact-icon`}
                  src={ICONS.TIKTOK}
                  onClick={() => window.open(LINK_TIKTOK)}
                />
              </div>
            </div>
            <div className={`${className}-right-box`}>
              {/* APP下载 */}
              {/* <div className={`${className}-section-box`}>
                <div className={`${className}-section-title`}>
                  {this.L.APP_DOWNLOAD}
                </div>
                <div
                  className={`${className}-download-btn`}
                  onClick={() => this.onDownloadAppleClick()}
                >
                  <div className={`${className}-download-btn-icon-box`}>
                    <img className={`${className}-download-btn-icon`} src={ICONS.APPLE} />
                  </div>
                  <div className={`${className}-download-btn-apple`} />
                </div>
                <div
                  className={`${className}-download-btn`}
                  onClick={() => this.onDownloadGoogleClick()}
                >
                  <div className={`${className}-download-btn-icon-box`}>
                    <img className={`${className}-download-btn-icon`} src={ICONS.ANDROID} />
                  </div>
                  <div className={`${className}-download-btn-android`} />
                </div>
              </div> */}
              {/* 条款声明 */}
              <div className={`${className}-section-box`}>
                <div className={`${className}-section-title`}>
                  {this.L.SECTION_TERMS}
                </div>
                {/* 用户协议 */}
                <div
                  className={`${className}-section-item`}
                  onClick={() => window.open(getTermsUrl())}
                >
                  {this.L.TERMS}
                </div>
                {/* 隐私政策 */}
                <div
                  className={`${className}-section-item`}
                  onClick={() => window.open(getPrivacyUrl())}
                >
                  {this.L.PRIVACY}
                </div>
                {/* Cookie政策 */}
                <div
                  className={`${className}-section-item`}
                  onClick={() => window.open(getCookieUrl())}
                >
                  {this.L.COOKIE}
                </div>
                {/* 反洗钱政策 */}
                <div
                  className={`${className}-section-item`}
                  onClick={() => window.open(getAmlUrl())}
                >
                  {this.L.AML}
                </div>
                {/* 行为准则 */}
                <div
                  className={`${className}-section-item`}
                  onClick={() => window.open(getConductUrl())}
                >
                  {this.L.CONDUCT_CODE}
                </div>
              </div>
              {/* 客服服务 */}
              <div className={`${className}-section-box`}>
                <div className={`${className}-section-title`}>
                  {this.L.CUSTOMER_SERVICE}
                </div>
                <div
                  className={`${className}-section-item`}
                  onClick={() => this.onHelpClick()}
                >
                  {this.L.HELP_CENTER}
                </div>
                <div
                  className={`${className}-section-item`}
                  onClick={() => this.onFeedbackClick()}
                >
                  {this.L.FEEDBACK}
                </div>
                <div
                  className={`${className}-section-item`}
                  onClick={() => this.onContactUsClick()}
                >
                  {this.L.CONTACTS}
                </div>
                <div
                  className={`${className}-section-item`}
                  onClick={() => this.onChargesClick()}
                >
                  {this.L.CHARGES}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebFooter;
