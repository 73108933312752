import asset from './asset';
import auth from './auth';
import common from './common';
import home from './home';
import qt from './qt';
import user from './user';

// ----------------------------------
// SERVER
// ----------------------------------
export const DOMAIN_NAME = 'woxbtc.pro';

const SERVER_DEVELOPMENT = `https://${DOMAIN_NAME}/api`;
const SERVER_PRODUCTION = `https://${DOMAIN_NAME}/api`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export const SERVER_PREFIX = `https://${DOMAIN_NAME}`; // 协议路径

export default {
  ...asset(SERVER),
  ...auth(SERVER),
  ...common(SERVER),
  ...home(SERVER),
  ...qt(SERVER),
  ...user(SERVER),
};
