/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ReactGA from 'react-ga';
import browserAttr from '../utils/browserAttr';
import { Drawer, List } from 'antd';

// components
import WebHeader from '../components/WebHeader';
import WebFooter from '../components/WebFooter';
import MobileHeader from '../components/MobileHeader';
import MobileFooter from '../components/MobileFooter';

// utils
import { isSignIn, signOut } from '../utils/user';
import { preventChina } from '../utils/chinaPreventer';

// asset
import { ICONS } from '../assets';

// languages
import L from './languages';

import './style.scss';

const className = 'pageLayout';

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);

    this.L = L();

    this.state = {
      isMobileMenuShow: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.common.isShowMenu && nextProps.common.isShowMenu) {
      this.setState({isMobileMenuShow: true});
    }
  }

  componentDidMount() {
    preventChina();
  }

  // private
  onMobileMenuClick = (menuName) => {
    if (menuName === this.L.MENU_HOME) {
      window.location.href = '/';
    } else if (menuName === this.L.MENU_ASSET) {
      window.location.href = '/asset';
    } else if (menuName === this.L.MENU_PLAN) {
      window.location.href = '/qt';
    } else if (menuName === this.L.MENU_ME) {
      window.location.href = '/me';
    } else if (menuName === this.L.LOGOUT) {
      signOut();
      window.location.href = '/';
    } else if (menuName === this.L.LOGIN) {
      window.location.href = '/signin';
    }
  }

  // render
  renderMobileDrawerItems = (item) => {
    let imgSrc;
    if (item === this.L.MENU_HOME) {
      imgSrc = ICONS.HEADER_HOME;
    } else if (item === this.L.MENU_ASSET) {
      imgSrc = ICONS.HEADER_ASSET;
    } else if (item === this.L.MENU_PLAN) {
      imgSrc = ICONS.HEADER_PLAN;
    } else if (item === this.L.MENU_ME) {
      imgSrc = ICONS.HEADER_ME;
    } else if (item === this.L.LOGIN) {
      imgSrc = ICONS.HEADER_LOGIN;
    } else if (item === this.L.LOGOUT) {
      imgSrc = ICONS.HEADER_LOGOUT;
    }

    return (
      <div className={`${className}-header-drawer`}>
        <img className={`${className}-header-drawer-icon`} src={imgSrc}/>
        {item}
      </div>
    );
  }

  renderMobile = () => {
    const menuList = [
      this.L.MENU_HOME,  // 资讯
      this.L.MENU_ASSET, // 资产
      this.L.MENU_PLAN, // 服务
    ];

    // 已登录
    if (isSignIn()) {
      menuList.push(this.L.MENU_ME);
      menuList.push(this.L.LOGOUT);
    } else {
      menuList.push(this.L.LOGIN);
    }

    return (
      <div className={`${className}`}>
        <Drawer
          closable={false}
          onClose={() => this.setState({ isMobileMenuShow: false })}
          visible={this.state.isMobileMenuShow}
        >
          <List
            dataSource={menuList}
            renderItem={item => (
              <List.Item
                onClick={() => {
                  this.onMobileMenuClick(item);
                  this.setState({ isMobileMenuShow: false });
                }}
              >
                {this.renderMobileDrawerItems(item)}
              </List.Item>
            )}
          />
        </Drawer>
        <div className={`${className}-mobile-box`}>
          <MobileHeader
            onNavMoreBtnClick={() => this.setState({ isMobileMenuShow: !this.state.isMobileMenuShow })}
          />
          <div className={`${className}-mobile-children-box`}>
            {this.props.children}
          </div>
          {this.props.common.isShowFooter?
          <MobileFooter />
          :null}
        </div>
      </div>
    );
  }

  renderWeb = () => {
    return (
      <div className={`${className}`}>
        <WebHeader />
        <div className={`${className}-web-box`}>
          <div className={`${className}-web-content`}>
            {this.props.children}
          </div>
          {this.props.common.isShowFooter?
          <WebFooter />
          :null}
        </div>
      </div>
    )
  }

  render() {
    if (browserAttr.versions.mobile) {
      return this.renderMobile();
    }

    return this.renderWeb();
  }
}

const mapStateToProps = state => ({
  common: state.common,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
