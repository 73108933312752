/**
 * 公共接口
 */
export default SERVER => ({
  /**
   * 用户基本信息
   * method GET
   */
  USER_DETAIL: `${SERVER}/user/profile`,

  /**
   * 我的团队
   * method GET
   */
  GET_MY_TEAM: `${SERVER}/user/invites`,

  /**
   * 用户实名认证 获取，提交
   * method GET / POST
   */
  USER_KYC: `${SERVER}/user/kyc`,
  
  /**
   * 修改密码
   * method POST
   */
  CHANGE_PWD: `${SERVER}/user/password`,

  /**
   * 设置交易密码
   * method POST
   */
  SET_TRADE_PWD: `${SERVER}/user/set_trade_pass`,

  /**
   * 修改交易密码
   * method POST
   */
  CHANGE_TRADE_PWD: `${SERVER}/user/change_trade_pass`,

  /**
   * 校验交易密码
   * method POST
   */
  CHECK_TRADE_PWD: `${SERVER}/user/check_trade_pass`,
  
  
});
