import { languageCode } from './language';
import { SERVER_PREFIX } from '../middleware/apis/index';
import { getUser } from '../utils/user';

// 隐私政策
export function getPrivacyUrl() {
  return `${SERVER_PREFIX}/docs/${languageCode()}/privacy.html`;
}

// 用户协议
export function getTermsUrl() {
  return `${SERVER_PREFIX}/docs/${languageCode()}/terms.html`;
}

// Cookie 政策
export function getCookieUrl() {
  return `${SERVER_PREFIX}/docs/${languageCode()}/cookie.html`;
}

// 反洗钱协议
export function getAmlUrl() {
  return `${SERVER_PREFIX}/docs/${languageCode()}/aml.html`;
}

// 行为准则
export function getConductUrl() {
  return `${SERVER_PREFIX}/docs/${languageCode()}/conduct.html`;
}

// 邀请链接
export function getShareUrl() {
  return `${SERVER_PREFIX}/invite/${getUser().invite_code}`;
}

// 根据名称跳转对应地址
export function redirect(type, url) {
  // if (!isSignIn()) { // 先登录
  //   window.location.href='/signin';
  //   return;
  // }

  // if (type == 'app') {
  //   if (url == 'Main') {
  //     window.location.href = '/';
  //   }
  //   if (url == 'Invitation') {
  //     window.location.href = '/me/team';
  //   }
  //   if (url == 'Profile') {
  //     window.location.href = '/me/profile';
  //   }
  //   if (url == 'Kyc') {
  //     window.location.href = '/me/real/name';
  //   }
  //   if (url == 'Address') {
  //     window.location.href = '/address/edit';
  //   }
  //   return;
  // }

  window.location.href = url;
}

