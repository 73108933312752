import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import AppLayout from '../layout';
import AsyncComponent from '../routes';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

const AppContainer = ({ store, history }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <AppLayout>
            <Suspense fallback={<LoadingPlaceholder />}>
              <Switch location={location}>
                <Route
                  exact
                  path='/'
                  render={props => <AsyncComponent {...props} componentName='Home' />}
                />
                <Route
                  exact
                  path='/article/:id'
                  render={props => <AsyncComponent {...props} componentName='HomeArticle' />}
                />
                <Route
                  exact
                  path='/help/:id'
                  render={props => <AsyncComponent {...props} componentName='HomeHelp' />}
                />

                <Route
                  exact
                  path='/signin'
                  render={props => <AsyncComponent {...props} componentName='SignIn' />}
                />
                <Route
                  exact
                  path='/signup'
                  render={props => <AsyncComponent {...props} componentName='SignUp' />}
                />
                <Route
                  exact
                  path='/forgetpwd'
                  render={props => <AsyncComponent {...props} componentName='ForgetPwd' />}
                />
                <Route
                  path='/invite/:code'
                  render={props => <AsyncComponent {...props} componentName='Invite' />}
                />
                
                <Route
                  exact
                  path='/qt'
                  render={props => <AsyncComponent {...props} componentName='Qt' />}
                />
                <Route
                  exact
                  path='/qt/record/:id'
                  render={props => <AsyncComponent {...props} componentName='QtRecord' />}
                />
                
                <Route
                  exact
                  path='/cash/in'
                  render={props => <AsyncComponent {...props} componentName='CashIn' />}
                />
                <Route
                  exact
                  path='/cash/out'
                  render={props => <AsyncComponent {...props} componentName='CashOut' />}
                />
                <Route
                  exact
                  path='/asset'
                  render={props => <AsyncComponent {...props} componentName='Asset' />}
                />
                <Route
                  exact
                  path='/guide/btc'
                  render={props => <AsyncComponent {...props} componentName='GuideBtc' />}
                />

                <Route
                  exact
                  path='/me'
                  render={props => <AsyncComponent {...props} componentName='Me' />}
                />
                <Route
                  exact
                  path='/me/team'
                  render={props => <AsyncComponent {...props} componentName='MyTeam' />}
                />
                <Route
                  exact
                  path='/me/share'
                  render={props => <AsyncComponent {...props} componentName='Share' />}
                />
                <Route
                  exact
                  path='/me/verification'
                  render={props => <AsyncComponent {...props} componentName='Kyc' />}
                />

                <Route
                  render={props => <AsyncComponent {...props} componentName='PageNotFound' />}
                />
              </Switch>
            </Suspense>
          </AppLayout>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
