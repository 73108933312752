/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_1: `титульная страница`,
    MENU_2: `Активы`,
    MENU_3: `Сервис`,
    MENU_4: `Мои`,
    SIGNIN: `Войти`,
    SIGNUP: `Зарегистрироваться`,
    SLOGAN: `Blockchain Bee`
};
