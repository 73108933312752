import React from 'react';
import { withRouter } from 'react-router-dom';

// utils
import  { isSignIn } from '../../utils/user';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentWebHeader';

class WebHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.L = L();
  }

  // private
  onAssetClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/asset';
  }

  onServiceClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/qt';
  }

  // render
  renderSignStatus = () => {
    const menu = window.location.pathname.split('/');
    return (
      <div className={`${className}-sign-box`}>
        <div
          className={`${className}-sign-title ${className}-sign-title${menu[1] === 'signin' ? '-checked' : ''}`}
          onClick={() => window.location.href='/signin'}
        >{this.L.SIGNIN}</div>
        <div className={`${className}-sign-seperator`} />
        <div
          className={`${className}-sign-title ${className}-sign-title${menu[1] === 'signup' ? '-checked' : ''}`}
          onClick={() => window.location.href='/signup'}
        >{this.L.SIGNUP}</div>
      </div>
    );
  }

  render() {
    // 当前菜单
    const menu = window.location.pathname.split('/');

    return (
      <div className={`${className}`}>
        <div className={`${className}-box`}>
          <div
            className={`${className}-left-box`}
            onClick={() => window.location.href='/'}
          >
            <img className={`${className}-logo`} src={ICONS.LOGO_TEXT} />
            <div className={`${className}-slogan`}>{this.L.SLOGAN}</div>
          </div>
          <div className={`${className}-right-box`}>
            <div
              className={`${className}-menu-title ${className}-menu-title${menu[1] === '' ? '-checked' : ''}`}
              onClick={() => window.location.href='/'}
            >{this.L.MENU_1}</div>
            <div
              className={`${className}-menu-title ${className}-menu-title${menu[1] === 'asset' ? '-checked' : ''}`}
              onClick={() => this.onAssetClick()}
            >{this.L.MENU_2}</div>
            <div
              className={`${className}-menu-title ${className}-menu-title${menu[1] === 'qt' ? '-checked' : ''}`}
              onClick={() => this.onServiceClick()}
            >{this.L.MENU_3}</div>
            {
              isSignIn() ?
              <div
                className={`${className}-menu-title ${className}-menu-title${menu[1] === 'me' ? '-checked' : ''}`}
                onClick={() => window.location.href='/me'}
              >{this.L.MENU_4}</div>
              :
              this.renderSignStatus()
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WebHeader);
