/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `表紙`,
    MENU_ASSET: `資産`,
    MENU_PLAN: `サービス`,
    MENU_ME: `マイ`,
    LOGOUT: `ログアウト`,
    LOGIN: `ログイン`
};
